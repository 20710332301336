import { LINKS } from '@utils/constants';
import mp4Video from '@video/uhv-web-hero.mp4';
import webmVideo from '@video/uhv-web-hero.webm';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';

import Carousel from '@components/Carousel/Carousel';
import Hero from '@components/Hero/Hero';
import ImageCTA from '@components/ImageCTA/ImageCTA';
import ButtonGroup from '@components/Layout/ButtonGroup/ButtonGroup';
import Columns from '@components/Layout/Columns/Columns';
import Content from '@components/Layout/Content/Content';
import Lead from '@components/Layout/Lead/Lead';
import PageSection from '@components/Layout/PageSection/PageSection';
import ApplicationListing from '@components/Listing/ApplicationListing';
import CategoryListing from '@components/Listing/CategoryListing';
import MailingList from '@components/MailingList/MailingList';
import Page from '@components/Master/Page';
import SEO from '@components/Master/SEO';
import Button from '@components/Molecule/Button/Button';
import Image from '@components/Molecule/Image/Image';
import EmbedVideo from '@components/Molecule/Video/EmbedVideo';
import ProductSearch from '@components/Search/ProductSearch';
import Copy from '@components/Typography/Copy';
import { MotionCopy } from '@components/Typography/Copy';
import H from '@components/Typography/H';
import { MotionH } from '@components/Typography/H';

const IndexPage = ({ data }: { data: any }) => {
	return (
		<Page transparent={true}>
			<SEO
				title="UHV Design | Ultra High Vacuum Components"
				description="UHV Design is a specialist in ultra-high vacuum components for heating and manipulation. We have many items available in stock today. View range."
			/>
			<Hero
				image={{
					src: getImage(data.hero),
					alt: 'UHV mechanical instrument',
				}}
				video={{
					mp4: mp4Video,
					webm: webmVideo,
					poster: data.hero.childImageSharp.original.src,
				}}
				type="stack">
				<Content>
					<MotionH level={1}>
						Trusted supplier of motion & manipulation solutions for
						the vacuum industry
					</MotionH>

					<Button to={LINKS.PRODUCTS} size="lg">
						View our product range
					</Button>
				</Content>
			</Hero>

			<PageSection>
				<Columns gap="lg">
					<Content>
						<Lead>
							<MotionH level={3} as="h2">
								Select a product type
							</MotionH>

							<MotionCopy theme="lead" spacing="sm">
								Using our in-site configuration tool, you can
								configure products to meet your exact
								specifications, preview and download them as an
								interactive 3D CAD file, and add configured
								products to your wishlist to automatically
								request a quote.
							</MotionCopy>
						</Lead>
					</Content>

					<div className="hide-mobile column--space">
						<ProductSearch type="standard" />

						<ButtonGroup spacing="auto">
							<Button
								to={LINKS.PRODUCTS}
								theme="ghost"
								align="end">
								View all products
							</Button>
						</ButtonGroup>
					</div>
				</Columns>

				<CategoryListing />
			</PageSection>

			<PageSection padding="sm" background="blue" overflow="breach">
				<Columns gap="xl">
					<Content>
						<Lead theme="white">
							<MotionH level={3} as="h2" className="test">
								Support
							</MotionH>

							<MotionCopy theme="lead" spacing="sm">
								Customer care and user needs are the foundations
								of the long standing relationships we have built
								over many years.
							</MotionCopy>
						</Lead>
					</Content>

					<div className="hide-mobile">
						<Image
							image={getImage(data.mechanism)}
							alt="UHVD mechanism"
							overflow
						/>

						<Button
							to={LINKS.SUPPORT}
							theme="ghost"
							align="end"
							style={{ marginLeft: 'auto', color: 'white' }}>
							Support
						</Button>
					</div>
				</Columns>
			</PageSection>

			<PageSection>
				<Columns>
					<Content>
						<Lead>
							<MotionH level={3} as="h2">
								Diverse applications
							</MotionH>

							<MotionCopy theme="lead" spacing="sm">
								Our products are used in a diverse range of
								applications from simple R&D experiments through
								to providing highly precise and reliable sample
								manipulation in some of the world's best surface
								science instruments and deposition tools.
							</MotionCopy>
						</Lead>
					</Content>

					<div className="column--bottom">
						<ButtonGroup spacing="auto">
							<Button
								to={LINKS.APPLICATIONS}
								size="lg"
								theme="ghost"
								align="end">
								Read more
							</Button>
						</ButtonGroup>
					</div>
				</Columns>

				<ApplicationListing />
			</PageSection>

			<PageSection>
				<Columns direction="reverse" gap="lg">
					<div>
						<EmbedVideo
							src="https://www.youtube.com/watch?v=2YZl2UTiZi0"
							title="We are UHV Design"
							poster={data.vidscreen.childImageSharp.fluid.src}
						/>
					</div>

					<div className="column--space">
						<Content>
							<Lead>
								<MotionH level={3} as="h2">
									About us
								</MotionH>

								<MotionCopy theme="lead" spacing="sm">
									UHV Design leads the field in high-tech
									motion, manipulation and heating product
									solutions for the vacuum industry.
								</MotionCopy>
							</Lead>
						</Content>

						<ButtonGroup spacing="auto">
							<Button to={LINKS.ABOUT} theme="ghost" align="end">
								Read about us
							</Button>
						</ButtonGroup>
					</div>
				</Columns>
			</PageSection>

			<PageSection padding="lg">
				<Columns stretch="former" gap="xs">
					<ImageCTA
						image={getImage(data.device)}
						alt="Device"
						to={LINKS.PRODUCT_HELP}>
						<H level={4} as="h3">
							Product help
						</H>
					</ImageCTA>

					<ImageCTA
						image={getImage(data.engineers)}
						alt="A pair of engineers building a product"
						to={LINKS.PRODUCT_CUSTOMISATION}>
						<H level={4} as="h3">
							Product customisation
						</H>
					</ImageCTA>
				</Columns>
			</PageSection>

			<PageSection padding="lg" background="grey" overflow="hidden">
				<Carousel heading="What our customers say">
					<div>
						<Content>
							<Copy>
								We have used UHV Design for a number of years
								now, always outstanding customer service, quick
								and efficient. They will always try and improve
								delivery dates. Very happy with the product and
								company.
							</Copy>

							<Copy>Would not hesitate to recommend.</Copy>
						</Content>

						<Content>
							<Copy font="lg" weight="bold">
								Tracey Howard
							</Copy>
							<Copy font="sm" spacing="none">
								Applied Microengineering Ltd
							</Copy>
						</Content>
					</div>

					<div>
						<Content>
							<Copy>
								We have been using UHV Design for over 15 years
								who supply us with magnetic transfer probes,
								push-pulls and feedthroughs. They also designed
								and continue to supply us with a custom transfer
								probe to our required specification.
							</Copy>

							<Copy>
								The team are highly knowledgeable and the
								customer service is excellent.
							</Copy>
						</Content>

						<Content>
							<Copy font="lg" weight="bold">
								Philippe Benard
							</Copy>
							<Copy font="sm" spacing="none">
								Ametek
							</Copy>
						</Content>
					</div>

					<div>
						<Content>
							<Copy>
								We have worked with UHV Design for a number of
								years now and they have always provided us with
								specialist parts at a high quality and accuracy.
							</Copy>

							<Copy>
								We have always found the company as a whole to
								be readily helpful and efficient - we look
								forward to working with them again over the
								months and years ahead.
							</Copy>
						</Content>

						<Content>
							<Copy font="lg" weight="bold">
								Richard Martin
							</Copy>
							<Copy font="sm" spacing="none">
								Deben UK Ltd
							</Copy>
						</Content>
					</div>

					<div>
						<Content>
							<Copy>
								We, Rivac Technology, have customers with a
								number of motion applications in UHV as well in
								HV. For these applications I choose UHV Design
								products already for many years and for a number
								of different motion feedthroughs because of the
								very high quality, resulting in a long lifetime
								and thus satisfied customers.
							</Copy>

							<Copy>
								On top of that, the customer service of UHV
								Design is excellent. Because of the quality of
								the products we do not need this service often,
								however if we need it, the problems are solved
								quick and adequate.
							</Copy>
						</Content>

						<Content>
							<Copy font="lg" weight="bold">
								Theo Bijvoets
							</Copy>
							<Copy font="sm" spacing="none">
								Rivac Technology BV
							</Copy>
						</Content>
					</div>

					<div>
						<Content>
							<Copy>
								I have used Z-Shift type devices from many
								manufacturers over the years, some better than
								others, some with better customer service and
								support than others. UHVDesign's Z-Shift devices
								have been by far the most reliable and best
								supported mechanisms that I've used.
							</Copy>
						</Content>

						<Content>
							<Copy font="lg" weight="bold">
								University of Wisconsin-Milwaukee
							</Copy>
						</Content>
					</div>
				</Carousel>
			</PageSection>
			<PageSection background="blue">
				<Columns>
					<MotionH level={2} as="h2" spacing="none">
						Join our mailing list
						<br /> for the latest updates
					</MotionH>
					<MotionCopy spacing="none">
						<MailingList />
					</MotionCopy>
				</Columns>
			</PageSection>
		</Page>
	);
};

export const query = graphql`
	query {
		hero: file(relativePath: { eq: "home/hero.jpg" }) {
			childImageSharp {
				gatsbyImageData
				original {
					src
				}
			}
		}
		mechanism: file(relativePath: { eq: "home/mechanism.png" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
		vidscreen: file(relativePath: { eq: "shared/vidscreen.jpg" }) {
			childImageSharp {
				fluid {
					src
				}
			}
		}
		device: file(relativePath: { eq: "home/device.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
		engineers: file(relativePath: { eq: "home/engineers.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
	}
`;

export default IndexPage;
